import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import './styles.css';
import CloseButtonImage from '../../assets/buttons/close-button.svg';
import ConfirmModal from '../confirm-modal';

function AccountEdit({ accountId, email, password, proxy, setEmail, setPassword, setHttpProxy, onClose, client }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClose = () => {
    if (!accountId){
      resetFields()
      onClose()
    } else {
      onClose()
    }
  };  

  const resetFields = () => {
    setEmail('');
    setPassword('');
    setHttpProxy('');
  };

  const handleUpdate = async () => {
    handleClose()
    try {
      const newProxy = `${proxy}`;
      let fetchUrl = `http://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/accounts/${accountId}`
      let method = `PUT`
      if (!accountId){
        fetchUrl = `http://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/accounts/${client}`
        method = `POST`
      }

      const response = await fetch(fetchUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
          proxy: newProxy,
        }),
      });

      const data = await response.json();  

      if (response.ok) {
        console.log('Update successful:', data);
        setHttpProxy(newProxy)
      
      } else {
        console.log('Update error:', data.message);
        // Handle error (e.g., show error message, etc.)
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle error (e.g., show error message, etc.)
    }
  };
  console.log({client})

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <button className="modal-edit-close-button specialbackground" onClick={handleClose}>
            <img src={CloseButtonImage} alt="close button" />
          </button>
          <div className='modal-edit'>
        <h1 style={{ alignItems: "center" }}>{email ? "Modifier un compte" : "Ajouter un compte"}</h1>
        <p>Email<input value={email || ''} onChange={(e) => setEmail(e.target.value)} /></p>
        <p>Mot de passe<input value={password || ''} onChange={(e) => setPassword(e.target.value)} /></p>
        <p>Proxy<input value={proxy || ''} onChange={(e) => setHttpProxy(e.target.value)} /></p>
      </div>
          <button className="action-button-edit" onClick={handleUpdate}>
            {accountId ? 'MODIFIER' : 'AJOUTER'}
          </button>
          {accountId ? (
            <button className="action-button-edit red specialbackground" onClick={() => setShowConfirmModal(true)}>
              Se déconnecter
            </button>
          ) : (
            <></>
          )}
          {showConfirmModal &&
            createPortal(
              <ConfirmModal onClose={() => setShowConfirmModal(false)} />,
              document.body
            )}
        </div>
      </div>
    </div>
  );
}

export default AccountEdit;