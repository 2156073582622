import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles.css';
import Header from '../components/header/index.js';
import Data from '../components/data/index.js';
import SearchBox from '../components/searchbox/index.js';
import Accounts from '../components/accounts';
import Logs from '../components/logs';

function AccountsDashboard() {
  const { name } = useParams();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
<div style={{display: "flex", flexDirection: "column", height: "100vh"}}>
  <Header />
  <div style={{display: "flex", flexDirection: "row", height: "100%", paddingTop:"20px", paddingBottom: "20px"}}>
    <div style={{flex: 1}}>
    <div className="client-header" style={{display: "flex", flexDirection: "row"}}>
      <p>{name}</p>
      </div>
      <div style={{display: "flex", flexDirection: "row"}}>
        <Data title='Messages envoyés' data={"sent"} client={name} />
        <Data title='Messages reçus' data={"received"} client={name}/>
      </div>
      <SearchBox
        onInputChange={handleInputChange} 
        placeholder={`Rechercher un compte de ${name}`}
        client={name}
      />
      <Accounts 
        inputValue={inputValue}
        client={name}
      />
    </div>
    <div style={{flex: 1, marginLeft: "10px", height: "100%"}}>
      <Logs client={name}/>
    </div>
  </div>
</div>


  );
}

export default AccountsDashboard;
