import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import './styles.css';
import SearchIcon from '../../assets/images/search_icon.svg';

import AccountEdit from '../account-edit';

function SearchBox({ source, placeholder, onInputChange, client }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [input, setInput] = useState('');
  const [email, setEmail] = useState(''); // Définir les valeurs initiales
  const [password, setPassword] = useState('');
  const [httpProxy, setHttpProxy] = useState('');


  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    onInputChange(inputValue);
  };
  return (
    <div className={source ? 'search-box-dashboard' : 'search-box'}>
      <div className="input-field">
        <img className='search-icon' src={SearchIcon} alt="search icon" />
        <input
          value={input}
          onChange={handleInputChange}
          className="search"
          name="search"
          placeholder={placeholder}
        />
      </div>
      <div className="button-container" >
        <button className="specialbackground"onClick={() => setShowEditModal(true)}>AJOUTER UN COMPTE</button>
        {showEditModal &&
            createPortal(
              <AccountEdit
                email={email}
                password={password}
                proxy={httpProxy}
                onClose={() => setShowEditModal(false)}
                setEmail={setEmail}
                setPassword={setPassword}
                setHttpProxy={setHttpProxy}
                client={client}
              />,
              document.body
            )}
      </div>
</div>
  )
}
export default SearchBox;