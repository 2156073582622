import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import EditButton from '../../assets/buttons/edit-button.svg'
import ValidateButton from '../../assets/buttons/validate-button.svg'

import 'react-toastify/dist/ReactToastify.css';

function ClientItem({id, clientName, clientAverage, clientDatas, clientLogs}){
    const [selectedOption, setSelectedOption] = useState('choose');

    const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };

    const selectClass = selectedOption !== 'choose' ? 'select-chosen' : '';

    return(
            <div className={id % 2 === 0 ? 'client-list-item' : 'client-list-item grey'}>
                <p className='data-client-item-1'>{clientName}</p>
                <p className='data-client-item-2'>{clientAverage}</p>
                <p className='data-client-item-3'>{clientDatas}</p>
                <p className='data-client-item-3'>{clientLogs}</p>
                <div className='data-client-item-4'>
                    <button className="image-button specialbackground">
                    <label htmlFor="action-selector"></label>
                        <select
                            name="selector"
                            id="action-selector"
                            value={selectedOption}
                            onChange={handleChange}
                            className={selectClass}
                        >
                            <option value="choose">Choisir</option>
                            <option value="reset">Réinitialiser mdp</option>
                            <option value="onboarding">Onboarding</option>
                            <option value="too_much_messages">Trop messages</option>
                        </select>
                    </button>
                    <button className="image-button specialbackground specialbackground">
                        <img src={ValidateButton} alt='validate'></img>
                    </button>
                    <Link to={`/client/${clientName}`}>
                        <button className="image-button specialbackground specialbackground" /*onClick={handleClick}*/>
                            <img src={EditButton} alt='edit'></img>
                        </button>
                    </Link>
                </div>
            </div>
    )
}

export default ClientItem