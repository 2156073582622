import React, { useEffect, useState } from 'react';
import './styles.css';
import ClientItem from '../client-item';

function Clients({ inputValue }) {
    const [clients, setClients] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [datas, setDatas] = useState([]);
    const [logs, setLogs] = useState([]);

    // Fetch accounts
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/accounts`)
            .then(response => response.json())
            .then(data => setAccounts(data))
            .catch(error => console.error('Error fetching accounts:', error));
    }, []);

    // Fetch clients
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/clients`)
            .then(response => response.json())
            .then(data => setClients(data))
            .catch(error => console.error('Error fetching clients:', error));
    }, []);

    // Fetch logs
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/logs/logins/get`)
            .then(response => response.json())
            .then(data => setLogs(data))
            .catch(error => console.error('Error fetching logs:', error));
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/logs/all/received`)
            .then(response => response.json())
            .then(data => setDatas(data))
            .catch(error => console.error('Error fetching logs:', error));
    }, []);

    let filtered = clients;
    if (inputValue) {
        filtered = clients.filter(client =>
            client.name.toLowerCase().startsWith(inputValue.toLowerCase())
        );
    }

    filtered.forEach(client => {
        const clientAccounts = accounts.filter(account => account.client?.id === client.id && account.client?.id !== null);
        const validAccounts = clientAccounts.filter(account => account.valid_session.data[0] === 1);
        client.average = `${validAccounts.length} / ${clientAccounts.length}`;
        const clientDatas = datas.filter(data => data.clientId === client.id);
        const clientLogs = logs.filter(log => log.clientId === client.id);
        console.log(clientDatas);
        if (clientDatas.length > 0) {
            client.datas = clientDatas[0].receivedMessages;
        } else {
            client.datas = "0";
        }
        if (clientLogs.length > 0) {
            const mostRecentLogDate = new Date(clientLogs[0].mostRecentLogin);
            const today = new Date();
            const isToday = mostRecentLogDate.toDateString() === today.toDateString(); // Simplified check for "is today"
    
            client.logs = isToday
                ? `Aujourd'hui à ${mostRecentLogDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })}`
                : `${mostRecentLogDate.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' })} à ${mostRecentLogDate.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', hour12: false })}`;
            // Store timestamp for sorting
            client.mostRecentLoginTimestamp = mostRecentLogDate.getTime();
        } else {
            client.logs = "+ de 7 jours";
            // Use a very old date for clients without logs to sort them to the end
            client.mostRecentLoginTimestamp = 0;
        }
        client.internal_id = client.id; 
    });
    filtered.sort((a, b) => b.datas - a.datas);

    const listItems = filtered.map(client => (
        <li key={client.internal_id}>
            <ClientItem
                id={client.internal_id}
                clientName={client.name}
                clientAverage={client.average}
                clientDatas={client.datas}
                clientLogs={client.logs}
                inputValue={inputValue}
            />
        </li>
    ));

    return (
        <div>
            <div className="account-item-box-client">
                <p className="data-client-1">Client</p>
                <p className="data-client-2">Comptes connectés</p>
                <p className="data-client-3">Messages envoyés</p>
                <p className="data-client-3">Dernière connexion</p>
                <p className="data-client-4">Action</p>
            </div>
            <hr />
            <div className="client-items-container">
                {listItems}
            </div>
        </div>
    );
}

export default Clients;
