import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom/client';
import './styles.css';

function Data({title, data, client}){

    const [logs, setLogs] = useState([]);

    useEffect(() => {
        const fetchAccounts = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/logs/${data}/${client}`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const datas = await response.json();
            setLogs(datas);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchAccounts();
      }, [client]);
      console.log(logs)
    return(
    <div className='rect-box'>
        <p className='title'>{title}</p>
        <hr></hr>
        <p className='number'>{logs}</p>
    </div>
    )
}

export default Data