import React, { useEffect, useState } from 'react';
import './styles.css';
import LogsItem from '../logs-item';

function Logs({client}) {

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/logs/${client}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setLogs(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAccounts();
  }, [client]);

  const listLogs =logs.map(logs => <li><LogsItem className='logs-item' id={logs.id} event={logs.event} createdAt={logs.createdAt} clientId={logs.clientId}/></li>);


  return (
    <div className='logs-box'>
      <p className='logs-title'>Logs</p>
      <hr/>
      {listLogs}
    </div>
  );
}

export default Logs;
