import React from 'react';
import Main from './main';

const App = () => {
  return (
    <div>
      {/* Place any other components or layout here */}
      <Main />
    </div>
  );
};

export default App;
