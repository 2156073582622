import './styles.css';

function formatDate(date) {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

function formatTime(date) {
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Date(date).toLocaleTimeString(undefined, options);
}

function LogsItem({ id, event, createdAt, clientId }) {
  const formattedDate = formatDate(createdAt);
  const formattedTime = formatTime(createdAt);

  return (
  <div className="logs-text" style={{ display: "flex", flexDirection: "row", gap: "10px", marginRight: "10px", marginLeft: "10px", justifyContent: "space-between" }}>
    <p style={{width: "185px", overflow: "clip"}}>{event}</p>
    <p>{formattedDate}</p>
    <p>{formattedTime}</p>
  </div>
  );
}

export default LogsItem;
