import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import AccountsDashboard from './pages/accounts-dashboard';
import LoginForm from './pages/login-form';

const Main = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
  
    if (token) {
      validateToken(token).then(valid => {
        setLoggedIn(valid);
        setLoading(false);
        if (!valid) {
          navigate('/login');
        }
      });
    } else {
      setLoading(false);
      navigate('/login');
    }
  }, [navigate]);
  
  


  return (
    loading ? (
      <div>Loading...</div>
    ) : (
      <Routes>
        <Route path="/login" element={loggedIn ? <Navigate to="/" /> : <LoginForm setLoggedIn={setLoggedIn} />} />
        <Route path="/" element={loggedIn ? <Dashboard/> : <Navigate to="/login" />} />
        <Route path="/client/:name" element={loggedIn ? <AccountsDashboard /> : <Navigate to="/login" />} />
      </Routes>
    )
  );  
};

async function validateToken(token) {
  try {
      const response = await fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/validate-token`, {
          headers: {
              Authorization: `Bearer ${token}`
          }
      });

      if (response.status === 200) {
          const data = await response.json();
          return data.valid;  // This assumes your backend returns { valid: true } for valid tokens
      } else {
          // Token validation failed
          return false;
      }
  } catch (error) {
      console.error('Failed to validate token', error);
      return false;
  }
}


export default Main;
