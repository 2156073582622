import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = ({ setLoggedIn }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { id, password } = event.target.elements;

    const response = await fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: id.value,
        password: password.value
      })
    });

    if (response.ok) {
      const { token } = await response.json();
      localStorage.setItem('token', token);
      setLoggedIn(true); // Set loggedIn state to true after successful login
      navigate('/'); // Optionally navigate to '/' directly from here
    } else {
      // Handle login error
      setError('Invalid credentials. Please try again.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        ID:
        <input type="text" name="id" required />
      </label>
      <label>
        Password:
        <input type="password" name="password" required />
      </label>
      <button className="specialbackground"type="submit">Log In</button>
      {error && <p>{error}</p>}
    </form>
  );
};

export default LoginForm;