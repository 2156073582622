import React, { useEffect, useState, useCallback } from 'react';
import './styles.css';
import AccountItem from '../account-item';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Accounts({ inputValue, client }) {
  const [accounts, setAccounts] = useState([]);
  const [open, setOpen] = React.useState(false);

  const requestOptions = useCallback(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-API-KEY", process.env.REACT_APP_UNIPILE_API_KEY);
    return {
      method: 'POST',
      body: JSON.stringify({body: 'test'}),
      headers: myHeaders,
    };
  }, [client]);

  const fetchAccounts = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/accounts/${client}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAccounts(data);
    } catch (error) {
      console.error(error);
    }
  }, [client]);

  useEffect(() => {
    fetchAccounts();
  }, [client, fetchAccounts]);


  const handleClick = async () => {
    setOpen(true);
    try {
      const accountsToFetch = accounts.filter(account => account.valid_session.data[0] === 0);
      for (let account of accountsToFetch) {
        const response = await fetch(`https://${process.env.REACT_APP_UNIPILE_SUBDOMAIN}.unipile.com:${process.env.REACT_APP_UNIPILE_PORT}/api/v1/accounts/${account.unipile_id}/restart`, requestOptions());
        console.log(response.body);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // Assuming you want to do something with the response data here
      }
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  let filtered = accounts;
  if (inputValue) {
    filtered = accounts.filter(account =>
      account.email.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  }

  filtered = filtered.map((account, index) => ({
    ...account,
    app_id: index,
  }));

  const listItems = filtered.map(account => (
    <li key={account.app_id}>
      <AccountItem accountId={account.id} accountName={account.full_name} accountPassword="password" accountProxy={account.unipile_id} app_id={account.app_id} inputValue={inputValue} validity={account.valid_session.data[0]} profilePicture={account.profilePicture}/>
    </li>
  ));

  return (
    <div className="account-box">
      <div className="wrapper-account">
        <p className="data-account-1">Comptes</p>
        
        <div className="button-container data-account-4">
            <button className="specialbackground" onClick={handleClick}>RECONNECTER</button>
        </div>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
              >
                Ordre de reconnexion envoyée à Unipile
              </Alert>
            </Snackbar>
      </div>
      <hr></hr>
      <ul>{listItems}</ul>
    </div>
  );
}

export default Accounts;
