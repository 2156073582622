import React, { useState } from 'react';
import './styles.css';
import Header from '../components/header/index.js';
import SearchBox from '../components/searchbox/index.js';
import Clients from '../components/clients';

function Dashboard() {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  return (
    <div>
        <Header />
        <SearchBox 
          onInputChange={handleInputChange} 
          source="dashboard"
          placeholder={"Rechercher un client"}
        />
        <Clients
          inputValue={inputValue}
        />
    </div>
  );
}

export default Dashboard;
