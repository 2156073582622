import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import './styles.css';
import ReconnectButton from '../../assets/buttons/reconnect-button.svg';
import ConfirmModal from '../confirm-modal';
import GreenDot from '../../assets/images/green_dot.svg'
import RedDot from '../../assets/images/red_dot.svg'

function AccountItem({ accountId, accountName, accountPassword, accountProxy, inputValue, app_id, validity, validityReason, profilePicture }) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [email, setEmail] = useState(accountName);
  const [httpProxy, setHttpProxy] = useState(accountProxy);

  const handleDeletion = async () => {
    try {
      let fetchUrl = `${process.env.REACT_APP_API_HTTP}://${process.env.REACT_APP_API_IP}:${process.env.REACT_APP_API_PORT}/api/accounts/${accountName}`
      let method = `DELETE`

      const response = await fetch(fetchUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });

      await response.json();  
      
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  console.log(profilePicture)
  return (
    <div className={app_id % 2 === 0 ? '' : 'grey'}>
      <div className='wrapper-account-item'>
        <img src={profilePicture} className='profile-picture data-account-item-1' alt="profile-pic" />
        <p className='account-name data-account-item-2'>{email}</p>
        <p className='account-name data-account-item-3'>{httpProxy.split("@")[0]}</p>
        <div className='account-name data-accout-item-4'>
          <div className='image-button lower'>
            {validity ? (
              <img src={GreenDot} alt='green'/>
            ) : (
              <div className='tooltip'>
                <img src={RedDot} alt='red'/>
                <span className='tooltiptext'>{validityReason}</span>
              </div>
            ) }
          </div>
        </div>
        <div className='action-button data-account-item-5'>
          <button className="image-button specialbackground" onClick={() => setShowConfirmModal(true)}>
          {
            validity !== 1 && (
              <img src={ReconnectButton} alt="Reconnect Button"/>
            )
          }
          </button>
        </div>
      </div>
    </div>
  );
}

export default AccountItem;
