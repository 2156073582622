import './styles.css';
import CloseButtonImage from '../../assets/buttons/close-button.svg'

function ConfirmModal({onClose, email, handleDeletion}) {

  if (email) {
    handleDeletion()
  }

  return (
    <div className="modal-overlay">
      <div className="modal-confirm">
        <div className="modal-content">
          <div className="modal-content">
          <button className='modal-confirm-close-button specialbackground' onClick={onClose}><img src={CloseButtonImage} alt='close button'></img></button>
          <h1 style={{alignItems: "center"}}>Etes vous sûr de vouloir supprimer le compte ?</h1>
          </div>
          <div className="modal-content">
            <button style={{alignItems: "center"}} onClick={onClose} className='action-button-edit specialbackground specialbackground'>Valider</button>
            <button style={{alignItems: "center"}} onClick={onClose} className='action-button-edit cancel specialbackground specialbackground'>Annuler</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmModal;