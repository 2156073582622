import React from 'react';
import { Link } from 'react-router-dom';
//import ReactDOM from 'react-dom/client';
import './styles.css';
import MirrorProfiles_Logo from '../../assets/images/mirror_profiles_logo.svg'

const back_icon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
<g clip-path="url(#clip0_982_476)">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.92251 3.89051C1.96985 3.77633 2.04998 3.67875 2.15278 3.61011C2.25557 3.54147 2.37641 3.50485 2.50001 3.50488H8.75001C9.32454 3.50488 9.89345 3.61805 10.4243 3.83791C10.9551 4.05777 11.4373 4.38003 11.8436 4.78629C12.2499 5.19255 12.5721 5.67484 12.792 6.20564C13.0118 6.73644 13.125 7.30535 13.125 7.87988C13.125 8.45442 13.0118 9.02332 12.792 9.55412C12.5721 10.0849 12.2499 10.5672 11.8436 10.9735C11.4373 11.3797 10.9551 11.702 10.4243 11.9219C9.89345 12.1417 9.32454 12.2549 8.75001 12.2549H3.12501C2.95925 12.2549 2.80028 12.189 2.68307 12.0718C2.56586 11.9546 2.50001 11.7956 2.50001 11.6299C2.50001 11.4641 2.56586 11.3052 2.68307 11.1879C2.80028 11.0707 2.95925 11.0049 3.12501 11.0049H8.75001C9.57881 11.0049 10.3737 10.6756 10.9597 10.0896C11.5458 9.50354 11.875 8.70868 11.875 7.87988C11.875 7.05108 11.5458 6.25623 10.9597 5.67017C10.3737 5.08412 9.57881 4.75488 8.75001 4.75488H4.00876L5.12939 5.87551C5.24324 5.99338 5.30623 6.15126 5.30481 6.31513C5.30338 6.47901 5.23765 6.63576 5.12177 6.75164C5.00589 6.86752 4.84914 6.93325 4.68526 6.93468C4.52139 6.9361 4.36351 6.87311 4.24564 6.75926L2.05814 4.57176C1.97068 4.48435 1.91111 4.37297 1.88697 4.25171C1.86283 4.13044 1.8752 4.00474 1.92251 3.89051Z" fill="white"/>
</g>
<defs>
  <clipPath id="clip0_982_476">
    <rect width="15" height="15" fill="white" transform="translate(0 0.379883)"/>
  </clipPath>
</defs>
</svg>
function Header(){
    return(
    <div className='flex-header'>
            <img src={MirrorProfiles_Logo} alt='logo_mp' width={143} height={54}></img>

        <Link to="/" style={{textDecoration: 'none'}}>
            <button className='back_button specialbackground'>{back_icon} Précédent</button>
        </Link>
    </div>
    )
}

export default Header